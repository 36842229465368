import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Avatar, Badge } from "antd";
import { FaRegUserCircle } from "react-icons/fa";

const MobileListItem = ({
	title,
	link,
	subset,
	setActiveNav,
	count,
	image,
	isOpen,
	setIsOpen,
	avatar,
}) => {
	if (!subset) {
		return count ? (
			<Badge count={count} color={"#e27626"} showZero>
				<Item onClick={() => setActiveNav(false)}>
					<Link to={link}>{title}</Link>
				</Item>
			</Badge>
		) : (
			<Item onClick={() => setActiveNav(false)}>
				<Link to={link}>{title}</Link>
			</Item>
		);
	} else {
		return !image ? (
			<div>
				<Item2 isOpen={isOpen}>
					{avatar ? (
						<div className={"title-avatar"}>
							<FaRegUserCircle size={30} className={"avatar-icon"} color="#344054" />
							<p>{title}</p>
						</div>
					) : (
						<p>{title}</p>
					)}

					<div
						className={"icon-container"}
						onClick={() => setIsOpen((prevState) => !prevState)}
					>
						{isOpen ? (
							<BiChevronUp className={"icon"} />
						) : (
							<BiChevronDown className={"icon"} />
						)}
					</div>
				</Item2>
				{isOpen && (
					<SubItem>
						{subset.map((item, index) => {
							return (
								<Link
									key={index}
									to={item.link}
									onClick={() => setActiveNav(false)}
								>
									{item.title}
									{item.email && (
										<span className={"edit-text"}>Edit profile</span>
									)}
								</Link>
							);
						})}
					</SubItem>
				)}
			</div>
		) : (
			<div>
				<Item2 isOpen={isOpen}>
					<div className={"title-avatar"}>
						<Avatar src={image} size={"large"} />
						<span className={"name"}>{title}</span>
					</div>
					<div
						className={"icon-container"}
						onClick={() => setIsOpen((prevState) => !prevState)}
					>
						{isOpen ? (
							<BiChevronUp className={"icon"} />
						) : (
							<BiChevronDown className={"icon"} />
						)}
					</div>
				</Item2>
				{isOpen && (
					<SubItem>
						{subset.map((item, index) => {
							return (
								<Link
									key={index}
									to={item.link}
									onClick={() => setActiveNav(false)}
								>
									{item.title}
									{item.email && (
										<span className={"edit-text"}>Edit profile</span>
									)}
								</Link>
							);
						})}
					</SubItem>
				)}
			</div>
		);
	}
};

const Item = styled.div`
	margin-bottom: 40px;
	a {
		font-weight: 550;
		color: ${({ theme }) => theme.colors.secondary};
		font-size: 18px;
	}
`;

const Item2 = styled.div`
	margin-bottom: ${({ isOpen }) => (isOpen ? "20px" : "40px")};
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title-avatar {
		display: flex;
		align-items: center;
	}

	.name {
		margin-left: 5px;
	}

	p {
		margin: 0;
	}

	p,
	.name {
		font-weight: 550;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.secondary};
		font-size: 18px;
	}

	.avatar-icon {
		margin-right: 5px;
	}

	.icon {
		margin: 0;
		animation-name: ${({ isOpen }) => (isOpen ? "rotate" : "rotate-reverse")};
		animation-duration: 0.5s;
	}

	.icon-container {
		padding: 3px 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		background: #f1c4a2;
		cursor: pointer;
	}
	@keyframes rotate {
		from {
			transform: rotate(180deg);
		}
		to {
			transform: rotate(0);
		}
	}

	@keyframes rotate-reverse {
		from {
			transform: rotate(180deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

const SubItem = styled.div`
	margin-bottom: 20px;
	padding-left: 30px;
	animation-name: ${({ isOpen }) => (isOpen ? "display" : "display-reverse")};
	animation-duration: 0.5s;

	a {
		display: block;
		font-weight: 500;
		color: ${({ theme }) => theme.colors.secondary};
		font-size: 15px;
		margin-bottom: 20px;
	}

	.edit-text {
		display: block;
		font-style: italic;
		font-size: 10px;
	}

	@keyframes display {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	@keyframes display-reverse {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export default MobileListItem;
