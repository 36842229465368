const environment = process.env.REACT_APP_ENV || "default";

const version = "1.2.1";

export const config = {
	default: {
		REACT_APP_BASEURL: process.env.REACT_APP_DEV_BASEURL,
		REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_DEV_MAPBOX_TOKEN,
		REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_DEV_GOOGLE_API_KEY,
		REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_DEV_PUBNUB_PUBLISH_KEY,
		REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_DEV_PUBNUB_SUBSCRIBE_KEY,
		REACT_APP_PAYSTACK_URL: process.env.REACT_APP_DEV_PAYSTACK_URL,
		REACT_APP_PAYSTACK_SECRET_KEY: process.env.REACT_APP_DEV_PAYSTACK_SECRET_KEY,
		REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_DEV_GOTRUCK_SIGNATURE,
		REACT_APP_GOTRUCK_TRACKING: process.env.REACT_APP_DEV_GOTRUCK_TRACKING,
		REACT_APP_GOTRUCK_COMPANY: process.env.REACT_APP_DEV_GOTRUCK_COMPANY,
		REACT_APP_TRACKING_BASEURL: process.env.REACT_APP_DEV_TRACKING_BASEURL,
		REACT_APP_ZOHO_URL: process.env.REACT_APP_DEV_ZOHO_URL,
		REACT_APP_ZOHO_TOKEN: process.env.REACT_APP_DEV_ZOHO_TOKEN,
		REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
		REACT_APP_SENDGRID_API_KEY: process.env.REACT_APP_DEV_SENDGRID_API_KEY,
		version,
	},
	development: {
		REACT_APP_BASEURL: process.env.REACT_APP_DEV_BASEURL,
		REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_DEV_MAPBOX_TOKEN,
		REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_DEV_GOOGLE_API_KEY,
		REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_DEV_PUBNUB_PUBLISH_KEY,
		REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_DEV_PUBNUB_SUBSCRIBE_KEY,
		REACT_APP_PAYSTACK_URL: process.env.REACT_APP_DEV_PAYSTACK_URL,
		REACT_APP_PAYSTACK_SECRET_KEY: process.env.REACT_APP_DEV_PAYSTACK_SECRET_KEY,
		REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_DEV_GOTRUCK_SIGNATURE,
		REACT_APP_GOTRUCK_TRACKING: process.env.REACT_APP_DEV_GOTRUCK_TRACKING,
		REACT_APP_GOTRUCK_COMPANY: process.env.REACT_APP_DEV_GOTRUCK_COMPANY,
		REACT_APP_TRACKING_BASEURL: process.env.REACT_APP_DEV_TRACKING_BASEURL,
		REACT_APP_ZOHO_URL: process.env.REACT_APP_DEV_ZOHO_URL,
		REACT_APP_ZOHO_TOKEN: process.env.REACT_APP_DEV_ZOHO_TOKEN,
		REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
		REACT_APP_SENDGRID_API_KEY: process.env.REACT_APP_DEV_SENDGRID_API_KEY,
		version,
	},
	production: {
		REACT_APP_BASEURL: process.env.REACT_APP_PROD_BASEURL,
		REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_PROD_MAPBOX_TOKEN,
		REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_PROD_GOOGLE_API_KEY,
		REACT_APP_PUBNUB_PUBLISH_KEY: process.env.REACT_APP_PROD_PUBNUB_PUBLISH_KEY,
		REACT_APP_PUBNUB_SUBSCRIBE_KEY: process.env.REACT_APP_PROD_PUBNUB_SUBSCRIBE_KEY,
		REACT_APP_PAYSTACK_URL: process.env.REACT_APP_PROD_PAYSTACK_URL,
		REACT_APP_PAYSTACK_SECRET_KEY: process.env.REACT_APP_PROD_PAYSTACK_SECRET_KEY,
		REACT_APP_GOTRUCK_SIGNATURE: process.env.REACT_APP_PROD_GOTRUCK_SIGNATURE,
		REACT_APP_GOTRUCK_TRACKING: process.env.REACT_APP_PROD_GOTRUCK_TRACKING,
		REACT_APP_GOTRUCK_COMPANY: process.env.REACT_APP_PROD_GOTRUCK_COMPANY,
		REACT_APP_TRACKING_BASEURL: process.env.REACT_APP_PROD_TRACKING_BASEURL,
		REACT_APP_ZOHO_URL: process.env.REACT_APP_PROD_ZOHO_URL,
		REACT_APP_ZOHO_TOKEN: process.env.REACT_APP_PROD_ZOHO_TOKEN,
		REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
		REACT_APP_SENDGRID_API_KEY: process.env.REACT_APP_PROD_SENDGRID_API_KEY,
		version,
	},
}[environment];

export const appConfig = {
	features: {
		bidding: false,
		saveAsDraft: false,
	},
};
