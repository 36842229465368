import styled from "styled-components";
import PrimaryCard from "../../layout/PrimaryCard";
import { Button, Form, Input, Radio } from "antd";
import { useState } from "react";
import InputAddress from "../AddressInput";
import DatePicker from "react-datepicker";
import ReactSnackBar from "react-js-snackbar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SelectTerminal from "../SelectTerminal";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { appConfig } from "../../../config";

const LocationForm = ({
	setData,
	data,
	setCurrentStep,
	handleChange,
	setStartCoordinates,
	setStartAddress,
	startAddress,
	startCoordinates,
	endAddress,
	setEndAddress,
	endCoordinates,
	setEndCoordinates,
	dropOffAddress,
	setDropOffAddress,
	dropOffCoordinates,
	setDropOffCoordinates,
	startDate,
	setStartDate,
	setVisibleTerminalList,
	setTerminalOpener,
	getCostId,
	loading_save_draft,
	saveDraft,
}) => {
	const [notificationState, setNotificationState] = useState({
		show: false,
		showing: false,
		message: "",
	});

	const navigate = useNavigate();

	const show = (message) => {
		if (notificationState.showing) return;

		setNotificationState({ show: true, showing: true, message });
		setTimeout(() => {
			setNotificationState({ show: false, showing: false });
		}, 3500);
	};

	const handleTripType = ({ target }) => {
		const newData = { ...data };
		newData.trip_category = target.value;
		setData(newData);
	};

	const handleTripTypeSelect = (value) => {
		const newData = { ...data };
		newData.trip_category = value;
		setData(newData);
	};

	const handleContinue = () => {
		if (data.cargo_type === "CONTAINER" && !data.container_number)
			return show("Please enter Container Number");
		if (!startCoordinates.lat) return show("Please enter Pickup Location");
		if (!endCoordinates.lat) return show("Please enter Drop Off Location");
		if (
			!data?.is_container_owner &&
			data?.cargo_type === "CONTAINER" &&
			!dropOffCoordinates.lat
		)
			return show("Please enter Empties Address");
		if (!startDate) return show("Please select Start Date & Time");

		getCostId();
		setCurrentStep(2);
	};

	return (
		<StyledForm layout={"vertical"}>
			<ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
				{notificationState.message}
			</ReactSnackBar>

			{data.cargo_type === "CONTAINER" && (
				<>
					<h2 className={"sub-title"}>Trip Type</h2>
					<div>
						<div
							className={"type-card-container"}
							onClick={() => handleTripTypeSelect("TERMINAL_LOCATION")}
						>
							<PrimaryCard
								image={"/icon-1.png"}
								title={"Terminal - Location"}
								info={"Move cargo from terminal to a location"}
							>
								<Radio
									checked={data.trip_category === "TERMINAL_LOCATION"}
									value={"TERMINAL_LOCATION"}
									name={"trip_category"}
									onChange={handleTripType}
								/>
							</PrimaryCard>
						</div>
						<div
							className={"type-card-container"}
							onClick={() => handleTripTypeSelect("LOCATION_LOCATION")}
						>
							<PrimaryCard
								image={"/icon-1.png"}
								title={"Location - Location"}
								info={"Move cargo from location to a location"}
							>
								<Radio
									checked={data.trip_category === "LOCATION_LOCATION"}
									value={"LOCATION_LOCATION"}
									name={"trip_category"}
									onChange={handleTripType}
								/>
							</PrimaryCard>
						</div>
					</div>
				</>
			)}

			{data?.cargo_type === "CONTAINER" && (
				<Form.Item
					label="Container Number"
					// name="container_number"
					required={false}
					className="input-item"
				>
					<div>
						<Input
							name="container_number"
							value={data.container_number}
							onChange={handleChange}
							size={"large"}
						/>
					</div>
				</Form.Item>
			)}

			<Form.Item
				label="Pick Up Location"
				// name="start_address"
				required={false}
				className="input-item"
			>
				<InputAddress
					address={startAddress}
					setAddress={setStartAddress}
					coordinates={startCoordinates}
					setCoordinates={setStartCoordinates}
				/>
				{data.trip_category === "TERMINAL_LOCATION" && data.cargo_type === "CONTAINER" && (
					<SelectTerminal
						setVisibleTerminalList={setVisibleTerminalList}
						opener={"start"}
						setTerminalOpener={setTerminalOpener}
					/>
				)}
			</Form.Item>

			<Form.Item
				label="Drop Off Location"
				// name="end_address"
				required={false}
				className="input-item"
			>
				<InputAddress
					address={endAddress}
					setAddress={setEndAddress}
					coordinates={endCoordinates}
					setCoordinates={setEndCoordinates}
				/>
				{/* {endAddress.trim().length === 0 && (
          <SelectTerminal
            setVisibleTerminalList={setVisibleTerminalList}
            opener={"end"}
            setTerminalOpener={setTerminalOpener}
          />
        )} */}
			</Form.Item>

			{!data?.is_container_owner && data?.cargo_type === "CONTAINER" && (
				<Form.Item
					label="Empties Drop Off Address"
					// name="drop_off_address"
					required={false}
					className="input-item"
				>
					<InputAddress
						placeholder="Enter your drop off location"
						address={dropOffAddress}
						setAddress={setDropOffAddress}
						coordinates={dropOffCoordinates}
						setCoordinates={setDropOffCoordinates}
					/>
				</Form.Item>
			)}

			<h2 className={"text-[#344054] mb-[20px] font-bold text-[18px]"}>
				Select Start Date and Time
			</h2>
			<DatePicker
				className={"date-picker"}
				selected={startDate}
				onChange={(date) => setStartDate(date)}
				timeInputLabel="Time:"
				dateFormat="MM/dd/yyyy h:mm aa"
				showTimeInput
			/>

			<div className="btn-container">
				<div className="flex items-center gap-3">
					<Button
						style={{ color: "#E27626", borderColor: "#E27626" }}
						onClick={() => setCurrentStep(0)}
					>
						Back
					</Button>

					{appConfig.features.saveAsDraft && (
						<Button
							type="default"
							style={{
								background: "#FFEEE2",
								color: "#E27626",
								borderColor: "#E27626",
							}}
							onClick={saveDraft}
						>
							{loading_save_draft ? (
								<ScaleLoader
									color={"#ffffff"}
									loading={loading_save_draft}
									size={100}
									width={5}
									margin={3}
									height={15}
								/>
							) : (
								"Save to Draft"
							)}
						</Button>
					)}
				</div>
				<Button type={"primary"} onClick={handleContinue}>
					Continue
				</Button>
			</div>
		</StyledForm>
	);
};

const StyledForm = styled(Form)`
	input {
		border-radius: 5px;
		height: 50px;
	}

	.btn-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 40px;
	}

	button {
		border-radius: 5px;
		width: 140px;
		height: 50px;
	}

	.sub-title {
		margin-bottom: 20px;
		color: #344054;
		font-weight: 600;
	}

	.type-card-container {
		margin-bottom: 30px;
	}

	label {
		font-weight: bold;
		font-size: 18px;
		color: ${({ theme }) => theme.colors.secondary};
	}

	.date-picker {
		width: 100% !important;
		border-radius: 5px;
		height: 50px;
		//margin-bottom: 10px;
		margin-top: -10px;
		border: 1px solid #cacaca;
		padding: 0 10px;
		outline: none;
	}
`;

const mapStateToProps = ({ loading_save_draft, saveToDraft }) => ({
	loading_save_draft,
	saveToDraft,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
