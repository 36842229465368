import { useState } from "react";
import styled from "styled-components";
import { Button, Input, InputNumber, Radio } from "antd";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Upload, message } from "antd";
import ReactSnackBar from "react-js-snackbar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import currency from "currency.js";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomSelect from "../Select";
import { CARGO_CATEGORIES, TRIP_TYPE } from "../../../constants/trip";
import BiddingCard from "../../layout/BiddingCard";
import logo from "../../../assets/gotruck-icon.svg";
import bidIcon from "../../../assets/bid-icon.svg";
import { appConfig } from "../../../config";

const { Dragger } = Upload;

const { TextArea } = Input;

const TdoInformation = ({
	estimatedPrice,
	estimatedUpperPrice,
	setCurrentStep,
	tdoExpiryDate,
	setTdoExpiryDate,
	data,
	setData,
	handleChange,
	getUploadUrl,
	imageUrl,
	setImageUrl,
	cargoImage,
	setCargoImage,
	setVisible,
	setVisible2,
	saveDraft,
	loading_save_draft,
}) => {
	const [fileList, setFileList] = useState([]);
	const [uploadUrl, setUploadUrl] = useState(null);
	const [loadingImage, setLoadingImage] = useState(false);
	const [s3ImageUrl, sets3ImageUrl] = useState(null);
	const [notificationState, setNotificationState] = useState({
		show: false,
		showing: false,
		message: "",
	});

	const show = (message) => {
		if (notificationState.showing) return;

		setNotificationState({ show: true, showing: true, message });
		setTimeout(() => {
			setNotificationState({ show: false, showing: false });
		}, 3500);
	};

	const beforeUpload = async (file) => {
		const isJpgOrPng =
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "application/pdf";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG/PDF file!");
			return;
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");
			return;
		}
		await getUploadUrl(file.type)
			.then(async (res) => {
				let url = res.payload.data.upload.url;
				let path = res.payload.data.upload.path;

				setUploadUrl(url);
				sets3ImageUrl(path);
				return isJpgOrPng && isLt2M;
			})
			.catch((e) => {
				message.error("Image must smaller than 2MB!");
				return;
			});
	};

	const handleAvatarChange = (info) => {
		setImageUrl(null);
		setFileList([]);
		data.profile_picture = null;
		let file = info.file;
		if (file.status === "removed") {
			setImageUrl(null);
			setFileList([]);
		} else if (file.status === "uploading") {
			setFileList([file]);
			setLoadingImage(true);
		} else if (file.status === "done") {
			let url = s3ImageUrl;
			setFileList([
				{
					uid: "current",
					name: file.name,
					status: "done",
					url: url,
				},
			]);
			setImageUrl(url);
			setLoadingImage(false);
		}
	};

	const handleProductImageChange = (info) => {
		setCargoImage(null);
		setFileList([]);
		data.profile_picture = null;
		let file = info.file;
		if (file.status === "removed") {
			setCargoImage(null);
			setFileList([]);
		} else if (file.status === "uploading") {
			setFileList([file]);
			setLoadingImage(true);
		} else if (file.status === "done") {
			let url = s3ImageUrl;
			setFileList([
				{
					uid: "current",
					name: file.name,
					status: "done",
					url: url,
				},
			]);
			setCargoImage(url);
			setLoadingImage(false);
		}
	};

	const handleCargoImageUpload = async ({
		file,
		onSuccess,
		onError,
		onProgress,
		setImageUrl,
	}) => {
		const xhr = new XMLHttpRequest();
		// S3 requires PUT method!
		xhr.open("PUT", uploadUrl);
		xhr.onreadystatechange = async () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					// Calls the update prop
					setCargoImage(s3ImageUrl);
					onSuccess(null, file);
				} else {
					message.error("Upload Failed");
				}
			}
		};
		xhr.upload.onprogress = (e) => {
			if (e.lengthComputable) {
				onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
			}
		};
		xhr.send(file);
	};

	const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
		const xhr = new XMLHttpRequest();
		// S3 requires PUT method!
		xhr.open("PUT", uploadUrl);
		xhr.onreadystatechange = async () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					// Calls the update prop
					setImageUrl(s3ImageUrl);
					onSuccess(null, file);
				} else {
					message.error("Upload Failed");
				}
			}
		};
		xhr.upload.onprogress = (e) => {
			if (e.lengthComputable) {
				onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
			}
		};
		xhr.send(file);
	};

	const props = {
		name: "file",
		beforeUpload: beforeUpload,
		multiple: true,
		action: uploadUrl,
		onChange: handleAvatarChange,
		customRequest: (options) => handleUpload(options),
		// fileList: {fileList || []}
	};

	const cargoImageProps = {
		name: "file",
		beforeUpload: beforeUpload,
		multiple: true,
		action: uploadUrl,
		onChange: handleProductImageChange,
		customRequest: (options) => handleCargoImageUpload(options),
	};

	const handleContinue = () => {
		if (data.cargo_type === "CONTAINER" && data.trip_category === TRIP_TYPE.TERMINAL_LOCATION) {
			if (!tdoExpiryDate) return show("Please enter TDO Expiry Date");
			if (!imageUrl) return show("Please upload TDO Image");
		}
		if (!data.cargo_value) return show("Please enter Cargo Value");

		if (data.cargo_type == "LOOSE_GOODS") {
			if (!data.cargo_category) return show("Please select Cargo Category");
			if (!cargoImage) return show("Please upload Cargo Image");
			if (!data.cargo_description) return show("Please enter Cargo Description");
		}

		if (data.acceptBid && !data.bid_price) return show("Please enter Bid Price");

		setVisible(false);
		setVisible2(true);
	};

	const categoryOptions = [
		{ label: "Construction Materials", value: CARGO_CATEGORIES.CONSTRUCTION_MATERIALS },
		{ label: "Automobiles", value: CARGO_CATEGORIES.AUTOMOBILES },
		{ label: "Food and Agriculture", value: CARGO_CATEGORIES.FOOD_AND_AGRICULTURE },
		{ label: "Dangerous Goods", value: CARGO_CATEGORIES.DANGEROUS_GOODS },
		{ label: "Electronics", value: CARGO_CATEGORIES.ELECTRONICS },
		{ label: "Pharmaceuticals", value: CARGO_CATEGORIES.PHARMACEUTICALS },
		{ label: "Livestock", value: CARGO_CATEGORIES.LIVESTOCK },
	];

	const tarrifDiff = estimatedUpperPrice - estimatedPrice;

	return (
		<StyledTdo>
			<ReactSnackBar Icon={<AiOutlineInfoCircle />} Show={notificationState.show}>
				{notificationState.message}
			</ReactSnackBar>

			{data?.trip_category == "TERMINAL_LOCATION" && data?.cargo_type == "CONTAINER" && (
				<>
					<div className={"input-container"}>
						<h2 className={"sub-title"}>TDO Expiry Date</h2>
						<DatePicker
							className={"date-picker"}
							selected={tdoExpiryDate}
							onChange={(date) => setTdoExpiryDate(date)}
							timeInputLabel="Time:"
							dateFormat="MM/dd/yyyy h:mm aa"
							showTimeInput
						/>
					</div>

					<div className={"input-container"}>
						<label className={"comment-label"} htmlFor={"tdoImage"}>
							TDO Image
						</label>
						<Dragger {...props} id={"tdoImage"}>
							<p className="ant-upload-text">
								<span style={{ color: "#e27626" }}>Click or upload</span> or drag
								and drop
							</p>
							<p className="ant-upload-hint">PNG, JPG or PDF(max 800x400px)</p>
						</Dragger>
					</div>
				</>
			)}

			<div className="space-y-2 mb-5">
				<label className={"comment-label mb-2"}>Cargo Value (₦)</label>

				<div className="selectContainer2">
					<InputNumber
						name="cargo_value"
						value={data?.cargo_value}
						formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
						onChange={(value) => setData({ ...data, cargo_value: value })}
						placeholder="Value of goods"
						controls={false}
						style={{ width: "100%", padding: "5px 0px" }}
					/>
				</div>
			</div>

			{data?.cargo_type == "LOOSE_GOODS" && (
				<div className="space-y-5">
					<div className="space-y-2">
						<label className={"comment-label mb-2"}>Cargo Category</label>

						<div className="selectContainer2">
							<CustomSelect
								options={categoryOptions}
								size="large"
								handleChange={(value) =>
									setData({ ...data, cargo_category: value })
								}
								value={data.cargo_category}
								bordered={false}
								className="w-full"
							/>
						</div>
					</div>

					<div className={"input-container"}>
						<label className={"comment-label"} htmlFor={"cargoImage"}>
							Cargo Image
						</label>
						<Dragger {...cargoImageProps} id={"cargoImage"}>
							<p className="ant-upload-text">
								<span style={{ color: "#e27626" }}>Click or upload</span> or drag
								and drop
							</p>
							<p className="ant-upload-hint">PNG, JPG or PDF(max 800x400px)</p>
						</Dragger>
					</div>
					<div className={"input-container mt-5"}>
						<label className={"comment-label"} htmlFor={"textarea"}>
							Cargo Description
						</label>
						<TextArea
							id="textarea"
							name="cargo_description"
							value={data.cargo_description}
							onChange={handleChange}
							rows={4}
							placeholder="Enter cargo description"
							className="textarea"
						/>
					</div>
				</div>
			)}

			<div className={"mt-5"}>
				<label className={"comment-label"} htmlFor={"textarea"}>
					Comment
				</label>
				<TextArea
					id={"textarea"}
					name="customer_comment"
					value={data.customer_comment}
					onChange={handleChange}
					rows={4}
					placeholder={"Leave Comment for the driver"}
					className={"textarea"}
				/>
			</div>

			<div className="space-y-2 mt-6 mb-12">
				<label className={"comment-label mb-2"}>Book Trip</label>

				<div className="space-y-4">
					<div onClick={() => setData({ ...data, acceptBid: false })}>
						<BiddingCard
							image={logo}
							title="Go Truck"
							desc="Use estimated price"
							info="Estimated total"
							isActive={!data?.acceptBid}
							value={
								estimatedPrice && (
									<span>
										{currency(estimatedPrice).format({ symbol: "₦" })} -{" "}
										{currency(estimatedUpperPrice).format({ symbol: "₦" })}
									</span>
								)
							}
						>
							<Radio checked={!data?.acceptBid} />
						</BiddingCard>
					</div>

					{appConfig.features.bidding && (
						<div onClick={() => setData({ ...data, acceptBid: true })}>
							<BiddingCard
								image={bidIcon}
								title="Bidding"
								desc="Suggest your own price"
								isActive={data?.acceptBid}
								content={
									<div className="flex items-center gap-3 font-[500] text-base">
										<InputNumber
											name="bid_price"
											value={data?.bid_price}
											formatter={(value) =>
												`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
											parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
											onChange={(value) =>
												setData({ ...data, bid_price: value })
											}
											placeholder="Bid price"
											controls={false}
											style={{ width: "100%", padding: "5px 0px" }}
										/>
									</div>
								}
							>
								<Radio checked={data?.acceptBid} />
							</BiddingCard>
						</div>
					)}
				</div>
			</div>

			<Estimate>
				<p className="font-semibold">
					PS: You will be charged an upfront payment of 80% upon booking. The remaining
					20% will be charged upon completion. Service charge and VAT will be added to the
					final price.
				</p>
			</Estimate>

			<div className={"btn-container"}>
				<div className="flex items-center gap-3">
					<Button
						style={{ color: "#E27626", borderColor: "#E27626" }}
						onClick={() => setCurrentStep(1)}
					>
						Back
					</Button>

					{appConfig.features.saveAsDraft && (
						<Button
							type="default"
							style={{
								background: "#FFEEE2",
								color: "#E27626",
								borderColor: "#E27626",
							}}
							onClick={saveDraft}
						>
							{loading_save_draft ? (
								<ScaleLoader
									color={"#ffffff"}
									loading={loading_save_draft}
									size={100}
									width={5}
									margin={3}
									height={15}
								/>
							) : (
								"Save to Draft"
							)}
						</Button>
					)}
				</div>

				<Button type={"primary"} onClick={handleContinue}>
					Continue
				</Button>
			</div>
		</StyledTdo>
	);
};

const StyledTdo = styled.div`
	.input-container {
		margin-bottom: 40px;
	}

	.sub-title {
		margin-bottom: 24px;
		color: #344054;
		font-weight: 600;
		width: "100%";
	}

	.selectContainer2 {
		border: 1px solid #d0d5dd;
		border-radius: 5px;
	}

	.date-picker {
		width: 100% !important;
		border-radius: 5px;
		height: 50px;
		margin-top: -10px;
		border: 1px solid #cacaca;
		padding: 0 10px;
		outline: none;
	}

	.comment-label {
		font-weight: 600;
		font-size: 20px;
		color: ${({ theme }) => theme.colors.secondary};
		margin-bottom: 50px;
	}

	.ant-upload {
		margin-top: 13px;
		border-radius: 5px;
		background: white;
		padding: 20px 0;
	}

	.textarea {
		border-radius: 5px;
		margin: 13px 0 40px;
	}

	.btn-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 40px;
	}

	button {
		border-radius: 5px;
		width: 140px;
		height: 50px;
	}

	.ant-upload-span {
	}

	.ant-upload-list-item-name {
		font-size: 17px;
		margin-bottom: 5px;
	}

	//.ant-progress-inner{
	//  height: 5px;
	//  color: #e27626;
	//  background: #e27626;
	//}
`;

const Estimate = styled.div`
	text-align: center;
	background: #fff3e9;
	padding: 20px 0;
	border-radius: 5px;

	span {
		font-weight: bold;
	}
	p,
	h1 {
		margin: 0;
		color: ${({ theme }) => theme.colors.primary};
	}

	h1 {
		font-size: 17px;
	}
`;

const mapStateToProps = ({ user, create_cost_loading, loading_save_draft }) => ({
	user,
	create_cost_loading,
	loading_save_draft,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TdoInformation);
